























































import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { useFooter, useTranslations } from '@vue-storefront/novulo';
import useUrlFormatter from '~/hooks/useUrlFormatter';

export default defineComponent({
  name: 'SignUp',
  setup() {
    const { footerResult, subscribeToNewsletter } = useFooter();
    const { translate } = useTranslations();

    const formatUrl = useUrlFormatter();

    const newsletterForm = ref(null);

    const isSubmitting = ref(false);
    const hasSubmitted = ref(false);

    const userData = {
      emailAddress: ''
    };

    const dirtyFields = {
      emailAddress: false
    };

    const newsletterSignupUsps = computed(() => {
      const uspsText = translate('newsletter_usps_list');
      return uspsText.split(' * ');
    });

    const newsletterSignupUrl = computed(() => {
      const urlText = translate('newsletter_url');
      const label = urlText.match(/\(.*?\)/g)?.[0]?.slice(1, -1) ?? '';
      const url = urlText.match(/\[.*?\]/g)?.[0]?.slice(1, -1) ?? '';
      return {
        label,
        url
      };
    });

    const handleSubmit = async () => {
      dirtyFields.emailAddress = true;
      const isFormValid = await newsletterForm.value.validate();
      if (!isFormValid) return;

      isSubmitting.value = true;
      subscribeToNewsletter(userData)
        .then((response) => {
          if (!response?.results?.every((command) => command.success)) return;
          hasSubmitted.value = true;
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    };

    return {
      footerResult,
      newsletterForm,
      isSubmitting,
      hasSubmitted,
      userData,
      dirtyFields,
      newsletterSignupUsps,
      newsletterSignupUrl,
      formatUrl,
      handleSubmit,
      translate,
      buttonText: footerResult.value.footer_subscribe.title
    };
  }
});
