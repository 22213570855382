




































































































































import { defineComponent, ref, useContext } from '@nuxtjs/composition-api';
import { useTranslations } from '@vue-storefront/novulo';
import { mediaUrlFallback } from '@/helpers/utils';
import useUrlFormatter from '~/hooks/useUrlFormatter';
import useMarketingTracking from '~/hooks/useMarketingTracking';
import useGoogleAnalytics, { eventBuilder } from '~/hooks/useGoogleAnalytics';

export default defineComponent({
  props: {
    logo: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { translate } = useTranslations();

    const isVisibleModal = ref(true);

    const hasAcceptedMarketing = ref(false);
    const hasAcceptedAnalytics = ref(true);

    const isSectionTwo = ref(false);

    const { i18n } = useContext();
    const formatUrl = useUrlFormatter();
    const {
      setMarketingConsentCookie,
      setAnalyticsConsentCookie,
      startTracking
    } = useMarketingTracking();
    const { registerEvent } = useGoogleAnalytics();
    const cookieStatementUrl = formatUrl(`/${i18n.t('cookie_statement_link')}`);

    const handleClose = () => {
      setMarketingConsentCookie(hasAcceptedMarketing.value);
      setAnalyticsConsentCookie(hasAcceptedAnalytics.value);
      registerEvent(eventBuilder('cookiesAccepted')());
      if (hasAcceptedMarketing.value) {
        startTracking();
      }
      isVisibleModal.value = false;
    };

    const handleSaveButtonClick = () => {
      handleClose();
    };

    const handleAcceptButtonClick = () => {
      hasAcceptedMarketing.value = true;
      hasAcceptedAnalytics.value = true;
      handleClose();
    };

    const handleToggleMarketingCookies = () => {
      hasAcceptedMarketing.value = !hasAcceptedMarketing.value;
    };

    const handleToggleAnalyticalCookies = () => {
      hasAcceptedAnalytics.value = !hasAcceptedAnalytics.value;
    };

    return {
      handleAcceptButtonClick,
      handleClose,
      handleSaveButtonClick,
      isVisibleModal,
      handleToggleMarketingCookies,
      handleToggleAnalyticalCookies,
      cookieStatementUrl,
      hasAcceptedAnalytics,
      hasAcceptedMarketing,
      isSectionTwo,
      translate,
      openSectionTwo() {
        isSectionTwo.value = true;
      }
    };
  },
  methods: {
    srcLogo() {
      return mediaUrlFallback(
        '/media/4e067df4-a171-4c3a-8cfb-63cb87a0cbbd/logo%20kamera%20express.svg'
      );
    }
  }
});
