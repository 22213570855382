

























































import { defineComponent } from '@nuxtjs/composition-api';
import { useFooter, useTranslations } from '@vue-storefront/novulo';

export default defineComponent({
  name: 'AskAdvice',
  setup() {
    const { footerResult } = useFooter();
    const { translate } = useTranslations();

    return { links: footerResult.value.footer_ask_advice, translate };
  }
});
