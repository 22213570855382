import { render, staticRenderFns } from "./Accordion.vue?vue&type=template&id=f48dfb6a&scoped=true&"
import script from "./Accordion.vue?vue&type=script&lang=ts&"
export * from "./Accordion.vue?vue&type=script&lang=ts&"
import style0 from "./Accordion.vue?vue&type=style&index=0&id=f48dfb6a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f48dfb6a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsChevronDownIcon24px: require('/var/www/packages/theme/components/icons/chevron-down/ChevronDownIcon24px.vue').default,IconsChevronUpIcon24px: require('/var/www/packages/theme/components/icons/chevron-up/ChevronUpIcon24px.vue').default})
