//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useRoute } from '@nuxtjs/composition-api';
import useMarketingTracking from '~/hooks/useMarketingTracking';
import LazyHydrate from 'vue-lazy-hydration';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    LazyHydrate
  },
  setup() {
    const route = useRoute();
    const { shouldShowCookieModal } = useMarketingTracking();

    return { route, shouldShowCookieModal };
  }
});
