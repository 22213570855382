import { render, staticRenderFns } from "./MiniCart.vue?vue&type=template&id=77d541f6&scoped=true&"
import script from "./MiniCart.vue?vue&type=script&lang=ts&"
export * from "./MiniCart.vue?vue&type=script&lang=ts&"
import style0 from "./MiniCart.vue?vue&type=style&index=0&id=77d541f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77d541f6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCartIcon: require('/var/www/packages/theme/components/atoms/CartIcon.vue').default,IconsChevronDownIcon16px: require('/var/www/packages/theme/components/icons/chevron-down/ChevronDownIcon16px.vue').default,AtomsKEButton: require('/var/www/packages/theme/components/atoms/KEButton.vue').default,MoleculesMiniCartItem: require('/var/www/packages/theme/components/molecules/MiniCart/CartItem.vue').default})
