
























































































































import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  useContext
} from '@nuxtjs/composition-api';
import { useKECart, useHeader, useTranslations } from '@vue-storefront/novulo';
import { useVSFContext, Logger } from '@vue-storefront/core';
import { datadogRum } from '@datadog/browser-rum';
import {
  addCurrency,
  priceFormat,
  getCurrencyCode,
  getBrowserDetails
} from '~/helpers/utils';
import useWindowResize from '~/hooks/useResizeWindow';
import useUrlFormatter from '~/hooks/useUrlFormatter';

export default defineComponent({
  name: 'MiniCart',
  props: {
    isDropdownOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const context = useContext();
    const vsfContext = useVSFContext();
    const { translate } = useTranslations();

    const HOVER_OPEN_DELAY_MS = 200;
    const OPEN_TO_CLOSE_DEBOUNCE_MS = 200;
    const { isDesktop } = useWindowResize();
    const { setCookieCartCount } = useKECart();

    onMounted(() => {
      setCookieCartCount();
    });

    const {
      getCart,
      cartItems,
      filteredCartItems,
      cartItemsCount,
      totalPrice,
      updateCart
    } = useKECart();
    const { headerResult } = useHeader();
    const formatUrl = useUrlFormatter();

    const homeLink = formatUrl('/');

    const cartItemsTotalPrice = computed(() =>
      filteredCartItems.value.reduce((acc, item) => acc + item.price, 0)
    );

    const discountTotal = computed(
      () => cartItemsTotalPrice.value - totalPrice.value
    );

    const cartLinks = computed(() => {
      const defaultLinks = {
        cartPage: '#',
        inspirationPage: '#'
      };
      if (!headerResult.value) {
        return defaultLinks;
      }
      const urlsContainer = headerResult.value.find(
        (item) => item.code === 'header_shoppingcart'
      );

      try {
        const urls = urlsContainer?.elements?.[0].urls;

        const cartPage = urls.find((item) => item.code_english === 'cart_page');
        const inspirationPage = urls.find(
          (item) => item.code_english === 'inspiration'
        );

        return {
          cartPage: cartPage?.url || defaultLinks.cartPage,
          inspirationPage: inspirationPage?.url || defaultLinks.inspirationPage
        };
      } catch (e) {
        Logger.error('MiniCart error: ', e);
        return defaultLinks;
      }
    });

    // add debounce from open to close
    const canCloseCart = ref(true);
    watch(
      () => props.isDropdownOpen,
      (isOpen) => {
        if (isOpen) {
          canCloseCart.value = false;
          setTimeout(() => {
            canCloseCart.value = true;
          }, OPEN_TO_CLOSE_DEBOUNCE_MS);
        }
      }
    );

    const handleToggle = () => {
      if (!canCloseCart.value) return;
      emit('toggleDropdown');
    };

    const handleCloseCart = () => {
      if (!canCloseCart.value) return;
      emit('toggleDropdown', false);
    };
    const clickOutsideConfig = {
      handler: handleCloseCart,
      middleware: (event) => {
        // check if click is inside the cart dropdown (there are two dropdowns, one for desktop and one for mobile)
        const cartDropdowns = Array.from(
          document.querySelectorAll('.mini-cart-dropdown')
        );
        return !cartDropdowns.some((cartDropdown) =>
          cartDropdown.contains(event.target)
        );
      },
      events: ['click']
    };

    // open cart on hover after delay
    let hoverTimeout;
    const handleMouseOver = () => {
      if (hoverTimeout || props.isDropdownOpen) return;
      hoverTimeout = setTimeout(() => {
        getCart();
        emit('toggleDropdown', true);
      }, HOVER_OPEN_DELAY_MS);
    };

    const handleMouseLeave = () => {
      if (!hoverTimeout) return;
      clearTimeout(hoverTimeout);
      hoverTimeout = null;
    };

    const handleRemoveItem = (item) => {
      const combidealIds =
        item.shoppingcart_combination_items?.map((item) => item.combideal_id) ||
        [];
      updateCart({
        spliId: item.spli_id,
        combidealIds,
        oldQuantity: item.quantity,
        newQuantity: 0,
        currency: getCurrencyCode({ vsfContext }),
        browserDetails: getBrowserDetails({ vsfContext, i18n: context.i18n })
      });
    };

    const openLink = (link: string, { action } = { action: null }) => {
      if (action) datadogRum.addAction(action);
      window.location.href = link;
    };

    return {
      isDesktop,
      cartItems,
      filteredCartItems,
      totalPrice,
      discountTotal,
      homeLink,
      cartLinks,
      handleToggle,
      handleCloseCart,
      handleMouseOver,
      handleMouseLeave,
      handleRemoveItem,
      openLink,
      translate,
      cartItemsCount,
      clickOutsideConfig,
      priceFormat,
      addCurrency
    };
  }
});
