



























export default {
  props: {
    size: {
      type: Number,
      default: 100
    },
    color: {
      type: String,
      default: 'black'
    }
  }
};
