




















































































import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { SfInput, SfIcon, SfButton } from '@storefront-ui/vue';

export default defineComponent({
  name: 'InputField',
  components: {
    SfInput,
    SfIcon,
    SfButton
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Form Input'
    },
    isValid: {
      type: Boolean,
      default: false
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: 'Error message value of form input.'
    },
    hasInfo: {
      type: Boolean,
      default: false
    },
    hasShowPassword: {
      type: Boolean,
      default: false
    },
    hasCheckmark: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup({ type }, { emit }) {
    const inputType = ref(type);

    const isPasswordVisible = computed(() => inputType.value !== 'password');

    return {
      handleChange: (newValue) => {
        emit('input', newValue);
      },
      switchVisibilityPassword() {
        if (type !== 'password') return;
        inputType.value = isPasswordVisible.value ? 'password' : 'text';
      },
      inputType,
      isPasswordVisible
    };
  }
});
