//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api';
import { VueShowdown } from 'vue-showdown';

export default defineComponent({
  name: 'MarkdownRender',
  components: {
    VueShowdown
  },
  props: {
    markdown: String
  }
});
